<template>
  <v-row no-gutters>
    <v-col :cols="addForm ? 6 : ''">
      <Table model="products" title="Active Products" subTitle="" :addons="[
        'filter',
        'sort',
        'search',
        'columns',
        'refresh',
        'mailme',
        'mail',
      ]" :exportOptions="['pdf', 'excel', 'csv', 'json']"
        :extendedMenus='["mail","clone", "freeze"]' @addData="addData" @rowAction="rowActions"
        @filter="true" @columnSelection="true" @saveLayout="true" :pagination="true" :infiniteScroll="true"
        :defaultLayout="null" :data="data" :defaultColumns="column" :defaultFilter="computeDefaultFilter()" @filterData="applyFilter"
        :hideAdd="true" :miniTab="miniTab" viewComponent="ManageProducts/listProduct/tab.vue" @reload="reload"/>
    </v-col>
  </v-row>
</template>
  
<script>
import activeproductService from "@/api/service/dms/productservice";
import Table from "@/components/Table.vue";
export default {
  components: {
    Table,
    // ListTable, SubSideMenu,TableTitle,
  },
  data() {
    return {
      showAdd: false,
      addForm: false,
      data: [],
      column: [],
      filterId: null,
      viewType: null,
      miniTab: {
        "title": "code",
        "subTitle": "name",
        "message": {
          "type": "column",
          "datatype": "date",
          "format": "MM/DD/YYYY",
          "label": "Created",
          "column": "created"
        },
        "status": "active",
        "statusDomain": "po-stage"
      },
      editDataId: null
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    async getTableData(obj) {
      let res = await activeproductService.findAll(obj?obj:{});
      this.data = res;
    },
    applyFilter(data){
      this.getTableData(data)
    },
    changeFilter(tab) {
      console.log(tab);
    },
    reload() {
      this.getTableData();
    },
    computeDefaultFilter() {
      return this.filterId
        ? { filterId: this.filterId }
        : { filter: { active: 1, deleted: 0 } };
    },
    addData() {
      this.addForm = true
      this.showAdd = false;
    },

    rowActions($event) {
      this.viewType = $event.type;
      this.editDataId = $event.data.id;
    },
    removeColumn(index) {
      if (index >= 0 && index < this.column.length) {
        this.column.splice(index, 1);
      }
    },
    copyData(i) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(this.data[i].orderNumber)
          .then(() => {
            console.log("Text successfully copied to clipboard");
          })
          .catch((err) => {
            console.error("Unable to copy text: ", err);
          });
      }
    },
  },
};
</script>
  
<style scoped>
</style>