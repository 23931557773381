import Dbmodel  from '../listmodel/index.vue'
import Menus from '../menus/index.vue'
import modules from '../modules/index.vue'
import Activity from '../activities/index.vue'
import activitygroups from '../activitygroups/index.vue'


export default [
    {
        path: 'technical',
        children: [
            {
                path: "dbmodel",
                component: Dbmodel,
            },
            {
                path: "menus",
                component: Menus,
            },
            {
                path:"module",
                component:modules,
            },
            {
                path:"activity",
                component:Activity,
            },
            {
                path:"activity-group",
                component:activitygroups,
            }
        ]
    }
]