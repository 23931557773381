import ApiService from "../ApiService.js";

const getModule = () => {
  return "auth";
};
const findAll = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/rolepermissions",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};
export default { findAll };
