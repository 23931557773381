import Licensor from "../licensor/index.vue"
import Characters from "../characters/index.vue"
import Properties from "../properties/index.vue"
import Royalty from '../royalty/index.vue'

export default [
    {
        path: 'licensing',
        children: [
            {
                path: "licensor",
                component: Licensor,
            },
            {
                path: "characters",
                component: Characters,
            },
            {
                path: "properties",
                component: Properties,
            },
            {
                path: "royalty",
                component: Royalty,
            },
            
        ]
    },

    
    
]
