<template>
    <v-list class="px-0" style="width:100%;height: 74vh;background-color: transparent;">

        <v-list-item class="px-0">
            <div style="display: flex;gap: 20px;padding-left: 5px;">
                <button>
                    <v-icon :color="'#0F62FE'">mdi-sort-variant</v-icon>
                </button>
                <div style="display: flex;justify-content: space-between;width: 100%;">
                    <button class="listTitle">
                        All Documents
                        <v-icon :color="'#6D7377'">mdi-menu-down</v-icon>
                        <v-menu activator="parent" location="bottom">
                            <v-list style="background-color: #f4f5f9;width: fit-content;">
                                <v-list-item class="item" v-for="(item, index) in columns" :key="index" :value="index">
                                    <div style="display: flex;align-items: center;justify-content: space-between;">
                                        <span class="optionText">{{ item.name }}</span>
                                        <i class="tibura-off_close pt-1"></i>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </button>
                    <button>
                        <v-icon :color="'#6D7377'">mdi-dots-vertical</v-icon>
                    </button>
                </div>
            </div>
        </v-list-item>

        <v-virtual-scroll :items="listData" class="auto-height-scroll">
            <template v-slot:default="{ item }">
                <v-list-item :class="{ 'my-selected-class': selectedItem === item.id }" class="singleItem py-3"  @click="$emit('rowClicked',item) ; selectedItem = item.id">
                    <v-row no-gutters>
                        <v-col cols="1" class="pr-6">
                            <input type="checkbox" v-model="item.checked" class="styled-checkbox"
                                :id="'styled-checkbox-' + item.id" />
                            <label :for="'styled-checkbox-' + item.id" class="styled-checkbox-label"></label>
                        </v-col>
                        <v-col class="pl-2">
                            <div class="orderNum">
                                {{ display && display.title ? item[display.title] : '' }}
                            </div>
                            <div class="companyName">
                                {{ display && display.subTitle ? item[display.subTitle] : '' }}
                            </div>
                            <div>
                                <span class="companyName">
                                    {{ display && display.message ? showMessage(item) : '' }}
                                </span>
                            </div>
                            <div>
                                <span class="statusChip" :class="getStatusClass(item)">
                                    {{ display && display.status ? showStatus(item) : '' }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-list-item>
            </template>
        </v-virtual-scroll>

    </v-list>
</template>

<script>
import moment from 'moment'
export default {
    props: ['title', 'stage', 'subTitle', 'multiSelect', 'listData', "display",'id'],
    data() {
        return {
            box: false,
            selectedItem:this.id
        }
    },
    methods: {
        moment,
        showMessage($data) {
            if (this.display.message.type == 'column') {
                return `${this.display.message.label} : ${this.display.message.datatype == 'date' ?
                    moment($data[this.display.message.column]).format(this.display.message.format)
                    : this.display.message.column
                    }`
            }
        },
        showStatus($data) {
            if (this.display.status == 'active') {
                return $data.active == 1 ? 'Active' : 'Inactive'
            }
        },
        getStatusClass($data) {
            if (this.display && this.display.status == 'active') {
                return `item-${$data.active == 1 ? 'active' : 'inactive'}`;
            } else if (this.display && this.display.statusDomain) {
                return `${this.display.statusDomain}-${$data[this.display.status]}`;
            }
            return ''; 
        }
    }
}
</script>

<style scoped>
.listTitle {
    color: #6D7377;
    font-size: 14px;
    font-weight: 500;
}
.item-active{
    background: #E58124;
    color: #fff;
}
.item-inactive{
    background: #E74702;
    color: #fff;
}
.po-stage-pending{
    background: #E58124;
    color: #fff;
}
.po-stage-due{
    background: #E7B402;
    color: #fff;
}
.po-stage-cancelled{
    background: #E74702;
    color: #fff;
}
.po-stage-received{
    background: #21C328;
    color: #fff;
}
.po-stage-In-Transit{
    background: #24ABE5;
    color: #fff;
}
.orderNum {
    color: #121619;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

.companyName {
    color: #6D7377;
    font-size: 13px;
    font-weight: 400;
    text-wrap: nowrap;
}


.statusChip {
    display: inline-flex;
    padding: 1px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
}

.singleItem {
    padding-right: 0;
    /* background-color:#0F62FE !important; */
}
.my-selected-class {
  background-color: #FFFFFF;
  border-left: 3.1px solid #0F62FE;
  /* color: #FFFFFF; */
}
/* .singleItem:hover {
    background-color:#edf5ff !important;
    border-radius: 0px;
} */

.styled-checkbox {
    display: none;
}

.styled-checkbox-label {
    position: relative;
    cursor: pointer;
    padding-left: 2rem;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid #6D7377;
        background-color: #fff;
        border-radius: 4px;
    }

    &::after {
        content: '';
        position: absolute;
        left: 40%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0.6rem;
        height: 0.6rem;
        border-bottom: 2px solid #0F62FE;
        border-right: 2px solid #0F62FE;
        transform-origin: 0% 50%;
        opacity: 0;
    }

    input:checked+&::before {
        border: 2px solid #0F62FE;
        background-color: #0F62FE;
    }

    input:checked+&::after {
        opacity: 1;
    }
}

::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    border-radius: 50px;
}
.auto-height-scroll {
  max-height: 100%; 
  overflow-y: auto; 
}
</style>