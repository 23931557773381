<template>
    <v-layout :data-theme="darkandlight">
      <v-main class="dashbordbodycontent" style="overflow: hidden !important;">
        <TopMenu v-if="$route.path != '/verification' && $route.path != '/login' && $route.path != '/autoLogin'"  @DarkAndLight="DarkAndLight" />
        <MainMenu  v-if="$route.path != '/verification' && $route.path != '/login' && $route.path != '/autoLogin'" />
        
        <SideMenu v-if="$route.path != '/login' && $route.path != '/autoLogin'" :userName="userName" :query="SideMenuData"></SideMenu>

        <div  :class="$route.path != '/login' ? 'pt-10 my-5 mr-3':'pt-0 my-0 mr-0'"
>
          
          <RouterView  :key="$route.fullPath">
          </RouterView>
        </div>  
      </v-main>
    </v-layout>
  </template>
  <script>
  import TopMenu from './Components/TopMenu.vue'
  import SideMenu from './Components/SideMenu.vue'
  import MainMenu from './Components/MainMenu.vue'
  export default {

    props: ["appTheme"],
    components: {
        TopMenu,
        SideMenu,
        MainMenu
    },
    computed: {
      getActivityGroup() {
        return this.$store.getters.getSideMenuGroup;
      },
      isLoaderRequired() {
        return this.$store.state.showLoader;
      },
    },
    data() {
      return {
        toggleRail: false,
        darkandlight: "light",
        userName: localStorage.getItem("_CUSER_"),
  
        drawer: true,
        items: [
          { title: "Home", icon: "mdi-home-city" },
          { title: "My Account", icon: "mdi-account" },
          { title: "Users", icon: "mdi-account-group-outline" },
        ],
        rail: true,
      };
    },
    created: function () {
   
    },
    methods: {
      DarkAndLight($value) {
        if ($value.title == "Dark And Light") {
          this.darkandlight = this.darkandlight ? "light" : "dark";
        }
      },
      menuClick($item) {
        console.log($item, "itemsssssssssss");
      }
    },
  };
  </script>
  
  <style scoped>
  >>>.v-overlay__content{
  top:104px !important
  }
  
  .margin-top-10px {
    margin-top: 10px;
  }
  
  .margin-top-0 {
    margin-top: 0;
  }
  >>>.v-navigation-drawer{
    /* margin-top: 59px; */
    border: none;
    border-radius: 2px;
  }
  </style>
  