<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden">
        <v-form @submit.prevent="save" ref="form">
            <v-card-item>
                <v-card-title class="dialog-Title">{{
                    viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
                }}
                    <span>Action</span>
                    <v-btn @click="close()"  elevation="0"  density="comfortable" icon="mdi-close"
                        class="x-closeIcon"></v-btn>
                </v-card-title>
            </v-card-item>

            <v-card-text>
                <v-row no-gutters class="mt-3 pa-2">
                    <v-col cols="12">
                        <v-radio-group :disabled="isRadioDisabled" v-model="formData.active" inline>
                            <v-radio color="primary" label="Active" :value="1"></v-radio>
                            <v-radio color="error" label="Inactive" :value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="4">
                        <v-label class="pb-1 label-text" >Code
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <TBTextField density="compact"  placeholder="Code" :rules="codeRule"
                            v-model="formData.code" :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
                        <v-error v-if="codeError">Already Exists!</v-error>
                    </v-col>

                    <v-col cols="12" class="">
                        <v-label class="pb-1 label-text" >Name
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <TBTextField density="compact" placeholder="Name" :rules="nameRule" v-model="formData.name"
                            :disabled="showReadOnly" />
                    </v-col>
                    <v-col cols="12" >
                        <v-label class="pb-1 label-text" >Event
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
                        <TBTextField density="compact" placeholder="Event"  v-model="formData.event"
                            :disabled="showReadOnly" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
                    <v-col cols="4" >
                    <v-btn class="dialogcancel mr-4" @click="dialog = close()">Cancel</v-btn>
                    <v-btn class="dialogsave" @click="save" type="submit">Apply Changes</v-btn>
                </v-col>
                </v-row>
            </v-card-action>
        </v-form>
    </v-card>
</template>
<script>
import { TBTextField } from "@tiburadev/vuecomponents";

export default {
    components: {
        TBTextField ,
    },

    data() {
        return {
            showReadOnly: false,
            dialog: false,
            type: null,
            codeRule: [(value) => !!value || "Code is Required."],
            nameRule: [(value) => !!value || "Name is Required."],
            codeError: false,
            formData: {
                code: null,
                name: null,
                event:null,
                active:1
            },
        };
    },

    watch: {
        id() {
            if (this.id) {
                this.formData = {};
                this.getById(this.id);
            }
        },
    },
    created() {
        if (this.id) {
            this.getById(this.id);
        }
        if (this.viewType) {
            this.showReadOnly = this.viewType == "edit" ? false : true;
        }
    },
    methods: {
        checkCode() {
        this.formData.code = this.formData && this.formData.code ?this.formData.code.toUpperCase() :'';
      },
        prevent(e) {
            if(e !== null){
            if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
            }}
        },
       
        close() {
            this.$emit("closeDialog");
        },
        
        async getById(id) {
            console.log(id)
        },

        async save() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid || this.codeError) {
                console.log("Not valid")
            }
            else {
                //this.formData.active = this.formData && this.formData.active == 1 ? true : false
                this.$emit('apply-changes',this.formData)
                
            }
        },
    },
};

</script>

<style>
.apply{
    background-color: #3C5AAA;
    color: #fff;
    width: 120px;
}
</style>
    