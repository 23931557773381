import { createRouter, createWebHistory } from 'vue-router'
import publicPages from './publicPages.js'
import Organization from '@/modules/Organization/routes/route'
import Identity from '@/modules/Identity/routes/route'
import Technical from '@/modules/Technical/routes/route'
import Misc from '@/modules/Miscellaneous/routes/route'
import Integration from '@/modules/Integration/routes/route.js'
import Tax from '@/modules/Tax/routes/route.js'
import ProductHeirarchy from "@/modules/ProductHeirarchy/routes/route.js"
import ManageProducts from "@/modules/ManageProducts/routes/route.js"
import ManageUpc from '@/modules/ManageUpc/routes/route.js'
import Licensing from "@/modules/Licensing/routes/route.js"
import Costing from '@/modules/Costing/routes/route.js'

const routes = [
  { path: '/login', component: () => import('../modules/authorize/Login.vue') },
  { path: '/autoLogin', component: () => import('../modules/authorize/AutoLogin.vue') },
  { path: '/logout', component: () => import('../modules/authorize/Logout.vue') },


  { path: '/', component: () => import('../Layout/Home.vue') },
  {
    path: "/admin",
    name: "Admin",
    children: [
      ...Organization,
      ...Identity,
      ...Technical,
      ...Misc,
      ...Integration,
      ...Tax,
    ]
  },
  {
    path: "/plm",
    name: "PLM",
    children: [
      ...ProductHeirarchy,
      ...ManageProducts,
      ...ManageUpc,
      ...Licensing,
      ...Costing
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const $token = localStorage.getItem("DMS_TOKEN");
  if (publicPages.includes(to.path)) {
    return next();
  }
  if ($token) {
    return next();
  } else {
    return next("/login");
  }
});

export default router
