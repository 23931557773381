import ProductCategory from "../catagories/index.vue"
import productType from "../productType/index.vue"
import ProductSubCategory from "../SubCategory/index.vue"
import Productsizes from "../sizes/index.vue"
import Colors from "../Colors/index.vue"
import productLine from "../productLine/index.vue"
import Attribute from "../attribute/index.vue"
import Material from "../Materials/index.vue"
import season from "../Season/index.vue"
import collections from "../collections/index.vue"
import gender from "../gender/index.vue"
import nrfSize from "../nrfSize/index.vue"
import nrfColor from "../nrfColor/index.vue"
import ConsumerClass from "../consumerClass/index.vue"
export default [
    {
        path: 'product-heirarchy',
        children: [
            {
                path: "product-category",
                component: ProductCategory,
            },
            {
                path: "product-type",
                component: productType
            },
            {
                path: "product-sub-category",
                component: ProductSubCategory,
            },
            {
                path:"product-sizes",
                component: Productsizes,
            },
            {
                path: "product-colors",
                component: Colors,
            },
            {
                path: "product-line",
                component: productLine,
            },
            {
                path: "product-attributes",
                component: Attribute,
            },
            {

                path: "product-material",
                component: Material,
            },
            {
                path: "product-season",
                component: season,
            },
            {
                path: "product-collections",
                component: collections,
            },
            {
                path: "product-nrf-size",
                component: nrfSize,
            },
            {
                path: "product-nrf-color",
                component: nrfColor,
            },
            {
                path: "gender",
                component: gender,
            },
            {
                path: "product-consumer-class",
                component: ConsumerClass,
            },
        ]
    },
]
