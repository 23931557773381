import Api from '../api/index.vue'
import Messaging from '../messaging/index.vue'
export default [
    {
        path: 'integration',
        children: [
            {
                path: "api",
                component: Api,
            },
            {
                path: "messaging",
                component: Messaging,
            }
        ]
    }
]