import ApiService from './ApiService.js'
const createSession = ($data) => {
    let $options = {
        "module":"apps",
        "options":{
            "endpoint":"/authLog",
            "method":"POST",
            "data":$data
        }
    }
    return ApiService.sendRequest($options)
}

const getModule = ($data) => {
    let $options = {
        "module":"apps",
        "options":{
            "endpoint":"/modules?roleId=101",
            "method":"GET",
            "data":$data
        }
    }
    return ApiService.sendRequest($options)
}

const getActivityGroup = ($data) => {
    let $options = {
        "module":"apps",
        "options":{
            "endpoint":"/activityGroups?roleId=101&moduleId=100",
            "method":"GET",
            "data":$data
        }
    }
    return ApiService.sendRequest($options)
}
const getActivity = ($data) => {
    let $options = {
        "module":"apps",
        "options":{
            "endpoint":"/activities?roleId=101&activityGroupId=1",
            "method":"GET",
            "data":$data
        }
    }
    return ApiService.sendRequest($options)
}
const getMenulist = ($data) => {
    let $options = {
        "module":"apps",
        "options":{
            "endpoint":"/menuList",
            "method":"GET",
            "data":$data
        }
    }
    return ApiService.sendRequest($options)
}

export default {createSession,getModule,getActivityGroup,getActivity,getMenulist}