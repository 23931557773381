import PricingComponent  from '../pricingComponent/index.vue'
import Hsn  from '../hsn/index.vue'

export default [
    {
        path: 'tax',
        children: [
            {
                path: "components",
                component: PricingComponent,
            },
            {
                path: "hsn",
                component: Hsn,
            }
        ]
    }
]