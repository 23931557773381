const publicPages = [
    "resetPassword", 
    "validateTeam", 
    "signup",
    "activate", 
    "authenticate",
    "companySignup", 
    "createUser", 
    "validateEmail",
    "findAllUser",
    "checkUser", 
    "authenticateFirebase", 
    "authInfo", 
    'getInvitation', 
    'acceptInvitation',
    'joinTeam',
    '/authLog'
]

const isPublic = ($page) =>{
    return publicPages.indexOf($page)<0 ? false : true
}
export default {isPublic};