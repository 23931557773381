<template>
    <v-card min-width="200">
        <v-card-title>
            Custom Sort
        </v-card-title>

        <v-card-text>
            <v-list class="listContainer">
                <v-list-item-group v-if="defaultcheckSelection && defaultcheckSelection.length > 0" @dragover.prevent @drop="handleDrop">
                    <v-list-item v-for="(item, index) in defaultcheckSelection" :key="index">
                        <v-list-item-title @click="checkSelection(item, index)" class="mb-2" :draggable="true"
                            @dragstart="($event) => dragStart($event, item, index)">
                            <input type="checkbox" v-model="selected" class="styled-checkbox" :id="getValue(item)" />
                            <label for="styled-checkbox" class="styled-checkbox-label"></label>
                            <v-label>{{ item.label }}</v-label>
                        </v-list-item-title>

                    </v-list-item>
                </v-list-item-group>
                <v-divider v-if="defaultcheckSelection && defaultcheckSelection.length > 0"/>
                <v-list-item-group v-if="displayColummns && displayColummns.length > 0" @dragover.prevent @drop="handleDrop">

                    <v-list-item v-for="(item, index) in displayColummns" :key="index">
                        <v-list-item-title @click="checkSelection(item, index)" class="mb-2" :draggable="true"
                            @dragstart="($event) => dragStart($event, item, index)">
                            <input type="checkbox" v-model="defaultt" class="styled-checkbox" :id="getValue(item)" />
                            <label for="styled-checkbox" class="styled-checkbox-label"></label>
                            <v-label>{{ item.label }}</v-label>
                        </v-list-item-title>

                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <!-- <div class="listContainer">
                <div v-if="defaultcheckSelection && defaultcheckSelection.length > 0" style="border: 1px solid #000;"
                    @dragover.prevent @drop="handleDrop" class="mb-1">
                    <span class="listTitle mb-2">Selected Sorts</span>
                    <template v-for="(item, index) in defaultcheckSelection" :key="index">
                        <div @click="checkSelection(item, index)" class="mb-2" :draggable="true"
                            @dragstart="($event) => dragStart($event, item, index)">
                            <input type="checkbox" v-model="selected" class="styled-checkbox" :id="getValue(item)" />
                            <label for="styled-checkbox" class="styled-checkbox-label"></label>
                            <v-label>{{ item.label }}</v-label>
                        </div>
                    </template>
                </div>

                <div v-if="displayColummns && displayColummns.length > 0" @dragover.prevent @drop="handleDrop">
                    <template v-for="(item, index) in displayColummns" :key="index">
                        <div @click="checkSelection(item, index)" class="mb-2" :draggable="true"
                            @dragstart="($event) => dragStart($event, item, index)">
                            <input type="checkbox" v-model="defaultt" class="styled-checkbox" :id="getValue(item)" />
                            <v-label class="styled-checkbox-label">{{ item.label }}</v-label>
                        </div>
                    </template>
                </div>
            </div> -->
        </v-card-text>

    </v-card>
</template>
  
<script>
export default {
    props: ['columns'],
    data() {
        return {
            displayColummns: [],
            showcheckbox: false,
            showdivider: false,
            defaultt: false,
            selected: true,
            defaultcheckSelection: [],
        };
    },

    watch: {
        columns() {
            this.displayColummns = this.columns;
        },
    },
    created() {
        this.displayColummns = this.columns;
    },
    methods: {
        dragStart(event, item, index) {
            event.dataTransfer.setData('text/plain', JSON.stringify({ item, index }));
        },

        handleDrop(event) {
            event.preventDefault();
            const draggedItem = JSON.parse(event.dataTransfer.getData('text/plain'));
            this.checkSelection(draggedItem.item);
        },
        getModel(data) {
            return this.defaultcheckSelection.includes(data);
        },
        getValue(data) {
            return data.label;
        },
        checkSelection(item) {
            this.showcheckbox = true;
            this.showdivider = true;
            const index = this.defaultcheckSelection.findIndex(existingItem => existingItem.id === item.id);
            if (index !== -1) {
                this.defaultcheckSelection.splice(index, 1);
                this.displayColummns.unshift(item);
                item.defaults = true;
            } else {
                const columnIndex = this.displayColummns.findIndex(existingItem => existingItem.id === item.id);
                if (columnIndex !== -1) {
                    this.displayColummns.splice(columnIndex, 1);
                    this.defaultcheckSelection.push(item);
                }
            }
            this.$emit('slectedSort', this.defaultcheckSelection);
        }

    },
};
</script>
  
<style scoped>
.listContainer {
    height: 55vh;
    overflow: auto;
}

.styled-checkbox {
    display: none;
}

.styled-checkbox-label {
    position: relative;
    cursor: pointer;
    padding-left: 2rem;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid #6d7377;
        background-color: #fff;
        border-radius: 3px;
    }

    &::after {
        content: "";
        position: absolute;
        left: 40%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0.6rem;
        height: 0.6rem;
        border-bottom: 2px solid #0f62fe;
        border-right: 2px solid #0f62fe;
        transform-origin: 0% 50%;
        opacity: 0;
    }

    input:checked+&::before {
        border: 2px solid #0f62fe;
        background-color: #0f62fe;
    }

    input:checked+&::after {
        opacity: 1;
    }
}

.listTitle {
    color: #6D7377;
    font-size: 14px;
    font-weight: 500;
}
</style>
  