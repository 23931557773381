<template>
    <v-row no-gutters>
      <v-col :cols="addForm ? 6 : ''">
        <Table model="product_categories" title="Categories" subTitle="" :addons="[
          'filter',
          'sort',
          'search',
          'columns',
          'refresh',
          'mailme',
          'mail',
        ]" :exportOptions="['pdf', 'excel', 'csv', 'json']"
          :extendedMenus='["edit", "delete", "mail", "view", "clone", "freeze"]' @addData="addData" @rowAction="rowActions"
          @filter="true" @columnSelection="true" @saveLayout="true" :pagination="true" :infiniteScroll="true"
          :defaultLayout="null" :data="data" :defaultColumns="column" :defaultFilter="computeDefaultFilter()" @filterData="applyFilter"
          :showAdd="showAdd" :miniTab="miniTab" viewComponent="ProductHeirarchy/catagories/tab.vue" @reload="reload"/>
      </v-col>
    </v-row>
  </template>
    
  <script>
  import CategoriesService from "@/api/service/products/categoriesService";
  import Table from "@/components/Table.vue";
  export default {
    components: {
      Table
      
    },
    data() {
      return {
        showAdd: false,
        addForm: false,
        data: [],
        column: [],
        filterId: null,
        viewType: null,
        miniTab: {
          "title": "code",
          "subTitle": "name",
          "message": {
            "type": "column",
            "datatype": "date",
            "format": "MM/DD/YYYY",
            "label": "Created",
            "column": "created"
          },
          "status": "active",
          "statusDomain": "po-stage"
        },
        editDataId: null
      };
    },
    created() {
      this.getTableData();
    },
    methods: {
      async getTableData(obj) {
        this.data = await CategoriesService.findAll(obj?obj:{});
      },
      applyFilter(data){
      this.getTableData(data)
    },
      changeFilter(tab) {
        console.log(tab);
      },
      reload() {
        this.getTableData();
      },
      computeDefaultFilter() {
        return this.filterId
          ? { filterId: this.filterId }
          : { filter: { active: 1, deleted: 0 } };
      },
      addData() {
        this.addForm = true
        this.showAdd = false;
      },
  
      rowActions($event) {
        this.viewType = $event.type;
        this.editDataId = $event.data.id;
      },
      removeColumn(index) {
        if (index >= 0 && index < this.column.length) {
          this.column.splice(index, 1);
        }
      },
      copyData(i) {
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(this.data[i].orderNumber)
            .then(() => {
              console.log("Text successfully copied to clipboard");
            })
            .catch((err) => {
              console.error("Unable to copy text: ", err);
            });
        }
      },
    },
  };
  </script>
    
  <style scoped>
  </style>