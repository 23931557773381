<template>
    <v-card width="100%" style="border-radius: 12px;overflow: hidden;">
      <v-card-item>
        <v-card-title  class="dialog-Title" >
          {{ viewType == 'edit' ? 'Edit' : viewType == 'view' ? 'View' : "Add" }}
          <span>ListModel</span>
          <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>
      <v-form @submit.prevent="save" ref="form">
        <v-card-text>
          <v-row no-gutters class="mt-3">
            <v-col cols="12">
              <v-label class="pb-1 label-text" >Name
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <TBTextField density="compact" :rules="nameRule" placeholder="Name" v-model="listModel.name"
                :disabled="showReadOnly" />
            </v-col>
          </v-row>
          <TBCardSelection :menuOptions="modelOptions" @cardSelected="cardSelected" />
          <v-row no-gutters class="mt-3" v-if="listModel.model_type && listModel.model_type=='table'">
            <v-col cols="12">
              <v-label class="pb-1" style="font-size:14px:color:#272727;font-weight:600">Models
                <span style="color:#CF0707;padding-left:2px;font-weight:700">*</span>
              </v-label>
              <TBAutoComplete :items="listModelData" item-title="value" v-model="listModel.model" item-value="value"
                :rules="ModelRule" :disabled="showReadOnly" :placeholder="'Models'" />
            </v-col>
            
          </v-row>
        
          <v-row v-if="listModel.model_type && listModel.model_type=='query'">
            <v-col cols="12">
            <v-label class="pb-1 label-text" >Query
                  <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
                  <span v-if="validQuery" class="text-caption">Validating Query</span>
            </v-label>
            
            <v-textarea variant="outlined" v-model="listModel.query" @change="updateTable"/>
            </v-col>
            <v-col cols="12">
              <v-label class="pb-1"  style="width:100%;font-size: 14px; color: #272727; font-weight: 600">Tables</v-label>
              <br/>
                <v-chip label class="ma-2" v-for="table in listModel.tables" v-bind:key="table">
                  {{table}}
                </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
       
        <v-card-actions class="mr-3">
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 dialogcancel" @click="setFalse()">Cancel</v-btn>
            <v-btn class="dialogsave"      :loading="loading"  type="submit" 
            v-if="(listModel.model_type=='query' &&  listModel.tables && listModel.tables.length>0) || (listModel.model_type!='query')"
            >Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import { TBCardSelection } from '@tiburadev/vuecomponents';
  import { TBAutoComplete } from '@tiburadev/vuecomponents';
  import { TBTextField } from '@tiburadev/vuecomponents';
  import ListModelService from '@/api/service/technical/ListModelService';
  import commonService from '@/api/service/common/CommonService';
  export default {
    props: ["id", "viewType"],
    components: {
      TBAutoComplete,
      TBTextField ,
      TBCardSelection
    },
    data() {
      return {
        // showReadOnly: false,
        loading:false,
        type: "",
        dialog: false,
        validQuery:false,
        add: false,
        size: {},
        listModelData: [],
        listModel: {},
        ModelRule: [(value) => !!value || "Models is Required."],
        nameRule: [(value) => !!value || "Name is Required."],
        modelOptions: {
              "columns": 2,
              "height": "80px",
              "showSearchBox": false,
              "outlineOnHover": true,
              "outlineOnSelection": true,
              "actions": [],
              "multiSelection": false,
              "selectedValue": "code",
              "scrollContainerHeight": "100px",
              "options": [
                  {
                      "title": "Table",
                      "code": "table",
                      "subTitle": "By Table",
                      "description": "Create model based on the table"
                  },
                  {
                      "title": "Query",
                      "code": "query",
                      "subTitle": "By Query",
                      "description": "Create model from the custom query"
                  }
              ]
        },
      };
    },
    watch: {
      id() {
        if (this.id) {
          this.getById(this.id);
          this.type1 = this.type
        }
      },
    },
    created() {
  
      this.getListModelData();
      if (this.id) {
        this.getById(this.id);
      }
      if (this.viewType) {
        this.showReadOnly = this.viewType == 'edit' ? false : true;
      }
    },
    methods: {
      cardSelected($item) {
  
              this.listModel.model_type = $item
      },
      setFalse() {
        this.$emit('closeDialog')
      },
      async updateTable() {
        this.validQuery=true
        let $tables = await ListModelService.getTables({"query":this.listModel.query})
        console.log($tables,"working88888888888")
        this.listModel.tables = $tables
        this.validQuery=false
  
      },
      async getById(id) {
        let res = await ListModelService.findOne(id);
        this.listModel = res[0];
      },
      async getListModelData() {
        let res = await commonService.getTables()
        console.log(res,"working77777777")
        this.listModelData = res.length > 0 ? res : []
      },
      async save() {
        this.loading =true
        if (this.listModel.model || this.listModel.query) {
          let output = await ListModelService.create(this.listModel);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          this.setFalse()
          this.dialog = false;
          this.$emit("reload", true);
        } else {
          this.loading =false;
          this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
        }
        
        
      },
    }
  
  }
  </script>
  
  <style></style>