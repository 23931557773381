import Role  from '../role/index.vue'
import ConfigurePermissions from '../configurepermissions/index.vue'
import Rolepermissions from '../rolepermission/index.vue'
import SecurityGroup from '../securityGroup/index.vue'
import User from '../user/index.vue'

export default [
    {
        path: 'identity',
        children: [
            {
                path: "role",
                component: Role,
            },
            {
                path: "permission-config",
                component: ConfigurePermissions,
            },
            {
                path: "rolepermission",
                component: Rolepermissions,
            },
            {
                path: "securitygroup",
                component: SecurityGroup,
            },
            {
                path: "user",
                component: User,
            }
        ]
    }
]