<template>
    <v-menu class="tab_menu" :activator="activator" open-on-hover>
        <v-list class="listStyle">

                <div style="background-color: #fff;width:200px;height: 100%;margin:0px 1px;">


                    <v-list-item-content  v-for="(item, itemIndex) in options" :key="itemIndex">
                        <v-list-item class="subTitjjle" @click="handleMenuItemClick(item)">
                            <v-icon size="x-small" :class="item.icon" />
                            {{ item.activity_group_name ? item.activity_group_name : null }}
                        </v-list-item>
                    </v-list-item-content>
                </div>

        </v-list>
    </v-menu>
</template>
  
<script>
export default {
    props: ["activator", "options", 'active','special','menu'],
    data() {
        return {
            menuItems: [],
            activateMenu: this.active,
        };
    },
    watch: {
      
    },
    created() {
      
    },

    methods: {
        handleMenuItemClick(item) {
            console.log('Menu Item Clicked:', item);
            this.$emit('optionselected', item);
            this.$store.commit("setMainMenu",this.activator)
        },
    },
};
</script>
  
<style scoped>
.tab_menu {
    display: flex;
    flex-direction: column;
    /* position: fixed; */
    border-radius: 4px;
    left: 0px;
    /* top: 58px !important; */
    /* position: relative !important; */
    /* top: 60px !important; */
}

.listStyle {
    background-color: #EEEEEE !important;
    overflow: hidden;
    display: flex;
    padding: 0;
    position: relative;
    top: 60px;
}

.menuTitle {
    /* background-color: #c1d2de !important; */
    text-transform: capitalize;
    font-size: 13px;
    color: #697077;
    padding-bottom: 5px;
    /* width: fit-content; */
}

>>>.v-overlay__content {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    top: 50px !important;
}

>>>.v-list-item--density-default.v-list-item--one-line {
    min-height: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
}

>>>.v-list-item__content {
    align-self: center;
    grid-area: content;
    overflow: hidden;
    width: fit-content;
    text-wrap: nowrap;
}

.subTitjjle {
    font-size: 12px;
    color: #121619;
    font-weight: 400;
    
}

.subTitjjle:hover {
    color: #121619;
    background-color: rgba(15, 98, 254, 0.10);
}
.viewAll {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 10px;
    color: #fff;
    background-color: #0f62fe;
    padding: 3px 6px;
    border-radius: 3px;
    font-weight: 600;
}
</style>
  