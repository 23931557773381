import UpcList from "../UpcList/index.vue"
import UnUsedUpc from "../UnUsedUpc/index.vue"
import usedUpc from "../UsedUpc/index.vue"
import useAllocationCreate from "../UpcAllocation/create.vue"
export default [
    {
        path: 'product-upc',
        children: [
            {
                path: "upc-list",
                component: UpcList,
            },
            {
                path: "useAllocationCreate",
                component: useAllocationCreate,
            },
            {
                path: "un-used-upc",
                component: UnUsedUpc,
            },
            {
                path: "used-upc",
                component: usedUpc,
            },

        ]
    }
]