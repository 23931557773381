import ApiService from "../ApiService.js";
import lodash from "lodash";
import moment from "moment";
const getModule = () => {
  return "auth";
};
const findAll = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/listmodels",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response;
};


const findAllOptions = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/getFilterTable",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};
const getTables = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/common/tables",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};
const getClass = (column,columnData) => {
  if(column){
    if(columnData.data_type == 'date' || columnData.data_type == 'datetime'){
      return moment(column).format(columnData.data_format)
    }else if(columnData && columnData.data_type && columnData.data_type.includes('varchar') || columnData.data_type == 'longtext' || columnData.data_type == 'text' || columnData.data_type == 'string'){
      return getCase(columnData.data_format , column) 
    }else if(columnData.data_type == 'bigint'){
      return column
    }else if( columnData.column_name == 'active'){
      return column == 1? 'Active' : 'Inactive'
    }else if( columnData.column_name == 'qrcode_required'){
      return column == 1? 'Yes' : 'No'
    }else {
      return column
    }
  }
};

const getCase = (dataFormat, column) => {
  switch (dataFormat) {
    case 'UPPERCASE':
      return column.toUpperCase();
    case 'LOWERCASE':
      return column.toLowerCase();
    case 'CAMELCASE':
      return toCamelCase(column);
    case 'CAPITALIZE':
      return capitalizeFirstLetter(column);
    default:
      return column; 
  }
};

const toCamelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
};

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getSort = async (data, key, type) => {
  try {
    let $data = lodash.orderBy(data, [key], [type]);
    return $data;
  } catch (e) {
    console.log(e);
    return data;
  }
};
export default { findAll, getTables, getSort, getClass,findAllOptions };
