<template>
  <Table model="divisions" title="Divisions" subTitle="" :addons="[
    'filter',
    'sort',
    'search',
    'columns',
    'refresh',
    'mailme',
    'mail',
  ]" :exportOptions="['pdf', 'excel', 'csv', 'json']"
    :extendedMenus='["edit", "delete", "mail", "view", "clone", "freeze"]' @addData="addData" @rowAction="rowActions"
    @filter="true" @columnSelection="true" @saveLayout="true" :pagination="true" :infiniteScroll="true"
    :defaultLayout="null" :data="data" :defaultColumns="column" :defaultFilter="computeDefaultFilter()" @filterData="applyFilter" :showAdd="showAdd"
    :miniTab="miniTab" viewComponent="Organization/divisions/tab.vue" @reload="reload" />

</template>
<script>
import DivisionService from "@/api/service/dms/DivisionService";
import Table from "@/components/Table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      data: [],
      column: [],
      filterId: null,
      viewType: null,
      editDataId: null,
      miniTab: {
        "title": "code",
        "subTitle": "name",
        "message": {
          "type": "column",
          "datatype": "date",
          "format": "MM/DD/YYYY",
          "label": "Created",
          "column": "created"
        },
        "status": "active",
        "statusDomain": "po-stage"
      },
    };
  },
  created() {
    // this.$store.commit('setSideMenuGroup',{"activity_group_code":"organisation"})
    this.getTableData();
  },
  methods: {
    async getTableData(obj) {
      let res = await DivisionService.findAll(obj?obj:{});
      this.data = res.data;
    },
    applyFilter(data){
      this.getTableData(data)
    },

    changeFilter(tab) {
      console.log(tab);
    },
    reload() {
      this.getTableData();
    },
    computeDefaultFilter() {
      return this.filterId
        ? { filterId: this.filterId }
        : { filter: { active: 1, deleted: 0 } };
    },
    addData() {
      alert('add')
    },
    exportData(type) {
      alert(type)
    },
    rowActions($event) {
      this.viewType = $event.type;
      this.editDataId = $event.data.id;

      console.log(this.viewType, this.editDataId, 'kkkkkkkkkaaa')
    },
    removeColumn(index) {
      if (index >= 0 && index < this.column.length) {
        this.column.splice(index, 1);
      }
    },
    copyData(i) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(this.data[i].orderNumber)
          .then(() => {
            console.log("Text successfully copied to clipboard");
          })
          .catch((err) => {
            console.error("Unable to copy text: ", err);
          });
      }
    },
  },
};
</script>
    
<style scoped>
.v-navigation-drawer {
  border: none;
  border-radius: 2px;
  background: unset;
  margin-top: 0px !important;
}
</style>