<template>
    <div style="background-color: #fff;width: 100%;border-radius: 10px;height: 74vh;">
        <div class="px-5 py-3 " style="display: flex;justify-content:space-between;border-bottom: 1px solid #EAEDF2;">
            <span class="mainTitle">
                Products
            </span>
            <span>
                <button class="mr-2" flat @click="closeDialog()">
                    <v-icon color="#0F62FE" size="x-large">mdi-pencil-circle-outline</v-icon>
                </button>
                
                <button  flat @click="closeDialog()">
                    <v-icon color="#0F62FE" size="x-large">mdi-dots-vertical-circle-outline</v-icon>
                </button>
            </span>
        </div>

        <div class="px-5 ">
            <v-tabs v-model="tab" class="mb-3" slider-color="#08939C">
                <v-tab v-for="item in items" :key="item" :value="item">
                    <span class="tabTitle">
                        {{ item }}
                    </span>

                </v-tab>
            </v-tabs>

            <v-window v-model="tab">
                <v-window-item v-for="item in items" :key="item" :value="item">
                    <component :is="getComponent(item)" @removeData="removeVariant" @dataUpdated="formDataUpdated"
                        :formData="formData" />
                </v-window-item>
            </v-window>
        </div>
    </div>
</template>
  
<script>
import Overview from './Overview.vue'
import DeliverySchedule from './DeliverySchedule.vue'
import Timelines from './Timelines.vue'
import Attachments from './Attachments.vue'
import Items from './Items.vue'
import mainData from './SampleData.json'

export default {
    components: {
        Overview,
        Items,
        DeliverySchedule,
        Attachments,
        Timelines,
    },

    data() {
        return {
            forSingleItem: mainData,
            formData: {},
            tab: 'Overview',
            items: [
                'Overview', 'Items', 'Delivery Schedule', 'Attachments',
                'Timelines'
            ]
        }
    },
    created() {
        const dataFromRoute = this.$route.query.data;
        if (dataFromRoute) {
            let singleData = mainData[dataFromRoute]
            this.formData = singleData
        }
    },

    methods: {
        removeVariant() {
            alert('remove me')
        },
        closeDialog() {
            this.$router.go(-1);
        },
        formDataUpdated(data) {
            this.formData = { ...this.formData, ...data };
            console.log(this.formData, 'final data')
        },
        save() {
            console.log(this.formData)
        },
        getComponent(tab) {
            const componentMap = {
                'Overview': 'Overview',
                'Items': 'Items',
                'Delivery Schedule': 'DeliverySchedule',
                'Attachments': 'Attachments',
                'Timelines': 'Timelines'
            };

            return componentMap[tab] || 'div';
        },
    },
}
</script>
  
<style scoped>
.mainTitle {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}

.tabTitle {
    text-transform: capitalize;
    color: #6D7377;
    font-size: 14px;
    font-weight: 400;
}
</style>
  