<template>
  <v-card class="ml-2 pa-3" flat min-height="74vh">
    <v-card-text class="pa-0">
      <v-form ref="form" @click.prevent>
        <v-row dense>
          <v-col cols="3">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">UPC Prefix
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TBAutoComplete :items="UpcPrefixData" item-title="upc_prefix" item-value="id" density="compact"
              placeholder="UPC Prefix" :rules="upcprefixRule" v-model="formData.upc_prefix"
              @update:modelValue="checkUpcData" :disabled="showReadOnly" />
          </v-col>
        </v-row>

        <v-row dense v-if="showCreate">
          <v-col cols="2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Company
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TBAutoComplete :items="companyData" item-title="name" item-value="id" density="compact" placeholder="Company"
              :rules="upcprefixcompanyRule" v-model="formData.company_id" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">productType
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TBAutoComplete :items="productTypeData" item-title="name" item-value="id" density="compact"
              placeholder="ProductType" :rules="upcprefixproducttypeRule" v-model="formData.product_type_id"
              :disabled="showReadOnly" />
          </v-col>

          <v-col cols="2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Division
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TBAutoComplete :items="divisionData" item-title="name" item-value="id" density="compact"
              placeholder="Division" :rules="divisionRule" v-model="formData.division_id"
              :disabled="showReadOnly" />
          </v-col>

          <v-col cols="2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">SBU
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TBAutoComplete :items="sbuData" item-title="name" item-value="id" density="compact"
              placeholder="Sbu" :rules="sbuRule" v-model="formData.sbu_id"
              :disabled="showReadOnly" />
          </v-col>
          <v-col cols="2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TBAutoComplete :items="categoryData" item-title="name" item-value="id" density="compact"
              placeholder="Category" :rules="upcprefixcategoryRule" v-model="formData.category_id"
              :disabled="showReadOnly" />
          </v-col>

          <v-col cols="2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Category
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TBAutoComplete :items="subCategoriesData" item-title="name" item-value="id" density="compact"
              placeholder="Sub Category" :rules="upcprefixsubcategoryRule" v-model="formData.sub_category_id"
              :disabled="showReadOnly" />
          </v-col>
        </v-row>

        <v-row v-if="showCreate" >
          <v-spacer></v-spacer>
          <v-btn flat class="cancel" @click="closeDialog">Cancel</v-btn>
          <v-btn v-if="showBtn" flat class="save" @click="save">Save</v-btn>
        </v-row>
      </v-form>
      <v-row v-if="showEdit">
        <v-col :cols="addForm ? 6 : ''">
          <Table model="upc_prefixes" title="UPC Allocation" subTitle="" :addons="['search', 'refresh', 'mailme', 'mail']"
            :exportOptions="['pdf', 'excel', 'csv', 'json']" :extendedMenus="[
              'edit',
              'delete',
              'mail',
              'view',
              'clone',
              'freeze',
            ]" @addData="addData" @rowAction="rowActions" @filter="false" @columnSelection="false" @saveLayout="true"
            :pagination="true" :infiniteScroll="true" :defaultLayout="null" :data="data" :defaultColumns="column"
            :defaultFilter="computeDefaultFilter()" @filterData="applyFilter" :hideAdd="true" :hideExport="true"
            :typeOfadd="'dialog'" @dialogOpen="dialogOpen" :noAdd="AddBtn" />
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>

<script>
import upcListService from "@/api/service/ManageUpc/upcListService";
import UpcPrefixService from "@/api/service/ManageUpc/UpcPrefixService";
import categoriesService from "@/api/service/products/categoriesService";
import SubCategoriesService from "@/api/service/products/SubCategoriesService";
import ProductTypeService from "@/api/service/products/ProductTypeService";
import CompanyService from "@/api/service/dms/CompanyService";
import { TBAutoComplete } from "@tiburadev/vuecomponents";
import Table from "@/components/Table.vue";
import DivisionService from "@/api/service/dms/DivisionService";
import SbuService from "@/api/service/dms/SbuService";
// import productservice from '@/api/service/dms/productservice';

export default {
  props: ["type", "tabName"],
  components: {
    TBAutoComplete,
    Table,
  },
  data() {
    return {
      sbuData: [],
      divisionData: [],
      showReadOnly:false,
      loading: false,
      showAdd: false,
      addForm: false,
      AddBtn: false,
      showBtn: false,
      data: [],
      column: [],
      filterId: null,
      viewType: null,
      miniTab: {
        title: "code",
        subTitle: "name",
        message: {
          type: "column",
          datatype: "date",
          format: "MM/DD/YYYY",
          label: "Created",
          column: "created",
        },
        status: "active",
        statusDomain: "po-stage",
      },
      tableData: {},
      showCreate: false,
      showEdit: false,
      upcData: null,
      categoryData: [],
      productTypeData: [],
      subCategoriesData: [],
      companyData: [],
      UpcPrefixData: [],
      formData: {
        upc_prefix: null,
        company_id: null,
        product_type_id: null,
        category_id: null,
        sub_category_id: null,
        sbu_id: null,
        division_id: null,
      },
      divisionRule: [(value) => !!value || "Division  Is Required"],
      sbuRule: [(value) => !!value || "Sbu Is Required"],
      upcprefixRule: [(value) => !!value || "UPC Prefix Is Required"],
      upcprefixcompanyRule: [(value) => !!value || "Company Is Required"],
      upcprefixproducttypeRule: [
        (value) => !!value || "productType Is Required",
      ],
      upcprefixcategoryRule: [(value) => !!value || "Category Is Required"],
      upcprefixsubcategoryRule: [
        (value) => !!value || "SubCategory Is Required",
      ],
    };
  },

  async created() {
    this.getCategoriesData();
    this.getComapaniesData();
    this.getSubCategoriesData();
    this.getProductTypeData();
    this.getUpcPrefix();
    this.getDivisionData();
    this.getSbuData();
  },


  methods: {
    dialogOpen() {
      this.showCreate = true
      this.showBtn = true
      this.AddBtn = true
    },
    async getById(id) {
      let res = await UpcPrefixService.findOne({ id: id});
      this.formData = res[0];
      console.log(this.formData , 'koooooooooo')
    },
    async getUpcPrefix() {
      let res = await upcListService.getUpcPrefixOnly();
      if (res.length > 0) {
        res = res.map((item) => item.upc_list.upc_prefix);
      }
      this.UpcPrefixData = res;
      console.log(this.UpcPrefixData, "working999999");
    },
    async getDivisionData() {
      let $res = await DivisionService.findAll();
      this.divisionData = $res.data;
      this.divisionData.unshift({ name: "Any", id: "0" });
    },
    async getSbuData() {
      let $res = await SbuService.findAll();
      this.sbuData = $res.data;
      this.sbuData.unshift({ name: "Any", id: "0" });
    },
    async getCategoriesData() {
      let $res = await categoriesService.findAll();
      this.categoryData = $res;
      this.categoryData.unshift({ name: "Any", id: "0" });
    },
    async getComapaniesData() {
      let res = await CompanyService.findAll();
      this.companyData = res.data;
      this.companyData.unshift({ name: "Any", id: "0" });
    },
    async getProductTypeData() {
      let res = await ProductTypeService.findAll();
      this.productTypeData = res;
      this.productTypeData.unshift({ name: "Any", id: "0" });
    },
    async getSubCategoriesData() {
      let res = await SubCategoriesService.findAll();
      this.subCategoriesData = res;
      this.subCategoriesData.unshift({ name: "Any", id: "0" });
    },
    closeDialog() {
      this.showCreate = false
        this.showBtn = false
        this.AddBtn = false
    },

    computeDefaultFilter() {
      return this.filterId
        ? { filterId: this.filterId }
        : { filter: { active: 1, deleted: 0 } };
    },
    rowActions($event) {
      this.formData = {}
      this.viewType = $event.type;
      this.formData.id = $event.data.id;
      if (this.viewType == 'edit') {
        this.showCreate = true
        this.showBtn = true
        this.AddBtn = true
        this.getById(this.formData.id)
      }else if (this.viewType == 'view') {
        this.showCreate = true
        this.showBtn = false
        this.AddBtn = true
        this.showReadOnly = true
        this.getById(this.formData.id)
      }
      console.log(this.viewType, this.formData.id, 'okkkkkkkkkkkkkkkkk')
    },
    async checkUpcData() {
      let res = await UpcPrefixService.findAll({
        upc_prefix: this.formData.upc_prefix,
      });
      console.log(res);
      if (res.length > 0) {
        this.showCreate = false;
        this.showEdit = true;
        console.log(res);
        this.data = res;
      } else {
        this.showCreate = true;
        this.showEdit = false;
      }
    },


    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log(validation);
      } else {
        delete this.formData.users;
        if (this.formData && this.formData.id) {
          let output = await UpcPrefixService.update(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.checkUpcData();
        } else {
          try {
            let output = await UpcPrefixService.create(this.formData);
            console.log(output, "output", this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.checkUpcData();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}

.cancel {
  background-color: #e1e1e1;
  font-size: 12px;
  text-transform: none;
  margin-right:10px;
}

.save {
  background: #0F62FE;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px !important;
  color: #fff;
  text-align: center;
  padding: 4px 4px;
  text-transform: none;
  margin-left:10px;
}
</style>
