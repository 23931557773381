import ApiService from "../ApiService.js";
const getModule = () => {
  return "auth";
};
const findAll = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/listmodels",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};
const getTables = async ($data)=> {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/tablesFromQuery",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
}
const create = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/listmodels",
      method: "POST",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};
const modelSchema = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/modelSchema",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};
export default { findAll,getTables,create,modelSchema };
