<template>
    <div style="display: flex;justify-content: space-between;margin-right: 5px;padding-left: 10px;">
        <span class="mainTitle">
            {{title}} 
        </span>
        <span style="display: flex;gap: 10px;">
           
            <button class="otherButton" v-if="!hideExport">
                <v-icon>mdi-download-outline</v-icon>
                Export

                <v-menu activator="parent" location="bottom">
                        <v-list style="background-color: #f4f5f9;width: fit-content;">
                            <v-list-item class="item" v-for="(item, index) in options" :key="index" :value="index"
                                @click="exportData(item)">
                                <div style="display: flex;align-items: center;justify-content: space-between;">
                                    <span class="optionText">{{ item }}</span>
                                </div>
                            </v-list-item>
                        </v-list>
                    </v-menu>
            </button>
            <button v-if="!hideAdd" class="newButton" @click="showAddBtn=false;$emit('addData')">
           <v-icon>mdi-plus</v-icon>Add 
            </button>
        </span>
    </div>
</template>

<script>
export default {
    props: ['title','options','hideAdd','hideExport'],

    data() {
        return {
            showAddBtn:true,
            exportArray: [
                        { title: "Excel" },
                        { title: "CSV" },
                        { title: "Pdf" }
            ],
        }
    },
    watch:{
        showAdd(){
            this.showAddBtn = this.showAdd
        }
    },
    methods:{
        exportData(type){
            this.$emit('exportData',type)
        }
    }

}
</script>

<style scoped>
.mainTitle {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.newButton {
    background: #0F62FE;
    /* border: 1px solid #CFCDCD; */
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #fff;
    text-align: center;
    padding: 5px 15px;

}

.otherButton {
    border: 1px solid #0F62FE;
    color: #0F62FE;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    padding: 5px 10px;
}
.optionText{
    text-transform: capitalize;
}

</style>