// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import colors from 'vuetify/lib/util/colors'
import { createVuetify} from 'vuetify'
// import { VDataTable } from 'vuetify/labs/VDataTable'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#F5F5F5',
    surface: '#FFFFFF',
    bgprimary: '#3C5AAA',
    primary: '#3C5AAA',
    scadulerCardbg:'#D0C2F9',
  }
}

export default createVuetify({
components,
directives,
  theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme,
        light: {
          dark: false,
          colors: {
            bgprimary:'#3C5AAA', // #E53935
            scadulerCardbg:colors.indigo.lighten4,
            background: '#F5F5F5',
          }
      },
      dark: {
          light: false,
          colors: {
            bgprimary: colors.grey.darken4, // #E53935
            scadulerCardbg:colors.grey.darken4,
            background: '#000000',
          }
      }
      }
    }
})


