<template>
    <v-app-bar class="topmenu" :elevation="0" >
        <img  :src="require('../../assets/AutoSST.svg')" 
        style="cursor: pointer;height: 45px;padding-left:10px" @click="$router.push('/')">

        <template v-slot:append>
            <GlobalSearch />
            <button class="menuBtn mr-4" v-bind="props" variant="text" @click="toggleTheme">
                <v-icon>mdi-theme-light-dark</v-icon>
            </button >
            <button class="menuBtn mr-4" v-bind="props" variant="text" @click="toggle">
                <v-icon>mdi-help-circle-outline</v-icon>
            </button >
        <div>

          
          
        <button class="menuBtn mr-4"  id="menu-activator" variant="text">
                <v-icon>mdi-cog-outline</v-icon>
            </button >
        <v-menu class="save-filter" activator="#menu-activator">
      <v-list  class="v-list-item-title ">
        <v-list-item
        class="v-list-item--density-default.v-list-item--one-line "
        @click="toggle"
          v-for="(item, index) in items"
          :key="index"
          :value="index"
        
        >
          <v-list-item-title >{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
            </div>
            <span v-bind="props" style="margin-left: 10px;display: flex;">
                <span >
                    <div style="font-size: 14px;font-weight: 400;color: #FFF;text-align: left;">{{ userName }}</div>
                    <div style="font-size: 12px;font-weight: 400;color: #e7e9ed;">Sr. Manager</div>
                </span>
                <span style="margin-left: 5px;height: 100%;color: #0F62FE;">
                    <v-icon>mdi-menu-down</v-icon>
                </span>
            </span>
    </template>
    </v-app-bar>
</template>
<script>
import GlobalSearch from '@/components/GlobalSearch.vue'
export default {
    components:{
        GlobalSearch
    },
    data() {
        return {
            userName: localStorage.getItem("_CUSER_"),
            items: [
        { title: 'LOG OUT' },
    
      ],
        }
    },
    methods:{
        toggle(){
            localStorage.removeItem('token')
            this.$router.push('/login')
        }
    }
}
</script>
<style scoped>
.topmenu {
    background: orange;
}
.v-list-item-title {
    min-height: 5px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}
.v-list-item--density-default.v-list-item--one-line {
    min-height: 15px !important;
}
</style>