<template>
    <div>
      <TBAutoComplete v-model="selectData" :items="enumData" item-title="display_value" item-value="data_value" :rules="rules"
      @update:modelValue="changeEnumData" :disabled="showReadOnly" :placeholder="placeholder" />
    </div>
  </template>
  
  <script>
import { TBAutoComplete } from "@tiburadev/vuecomponents";
import EnumService from "@/api/service/miscellaneous/EnumService";
import commonService from '@/api/service/common/CommonService';
  
  export default {
    components: {
      TBAutoComplete,
    },
    props: ["domain", 'placeholder','selected','showReadOnly','rules'],
    data() {
      return {
      
        selectData:null,
        enumData: [],
        holder: "Task Type"
      };
    },
    
    watch: {
      domain(){
        this.getEnumData()
      },
      selected() {
        // alert('workinggg')
        if (this.selected) {
          this.selectData =this.selected
        }
        else{
          this.selectData =null
        }
      },
    },
    created() {
      if(this.selected){
         this.selectData = this.selected
      }
      this.getEnumData();
    },
  
    methods: {
      // async getEnum() {
      //   let res = await EnumService.findAll();
      //   this.data = res;
      //   console.log(this.data, "enummmmm");
      // },
      changeEnumData() {
        this.$emit('changeEnumData', this.selectData)
      },
      async getEnumData() {
        let res = await EnumService.findAll({ 'domain': this.domain,active:1 })
        this.enumData = res.length > 0 ? await commonService.getSort(res,'display_value','asc') : [];
      },
      setData(data){
        console.log(data,"pppppppppppp7777")
      }
    },
  };
  </script>
  
  <style>
  
  >>>.v-field--disabled {
      pointer-events: none;
      opacity: 0.8 !important;
  }
  </style>