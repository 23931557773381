import axios from 'axios'
import config from '../config'
import publicPages from './publicPages'
import queryString from 'querystring'

const getHeaders = ($contentType) => {
    return {
        'Content-Type': $contentType ? $contentType : 'application/json'
    }
}
const getAuthorization = ($options,$headers) =>{
    if(publicPages.isPublic($options.options.endpoint)) {
        $headers.entity = $options.authorization && $options.authorization.entity
        $headers.action = $options.authorization && $options.authorization.action
    } else {
        $headers.authorization = localStorage.getItem('DMS_TOKEN')
        $headers.AppKey= config.appDetails.apiKey
        $headers.Environment= config.appDetails.environment
    }
}
const getBaseUrl=($options) =>{
    if($options.options.data && $options.options.method=='GET') {
        return `${config.baseUrl[$options.module]}${$options.options.endpoint}?${queryString.stringify($options.options.data)}`
    } else {
        return `${config.baseUrl[$options.module]}${$options.options.endpoint}`
    }
}
const sendRequest = async ($options) =>{
    let $headers = {"headers":getHeaders($options.authorization)}
    getAuthorization($options,$headers.headers)
    if($options.downloadAsFile) {
        $headers.responseType='blob'
    }
    switch($options.options.method) {
        case 'POST':
            return axios.post(getBaseUrl($options),$options.options.data,$headers)
            .then($response =>{
                return Promise.resolve($response.data)
            }).catch(exception=>{
                console.log(exception);
                return Promise.reject(null)
            });
        case 'GET':
            return axios.get(getBaseUrl($options),$headers)
            .then($response =>{
                return Promise.resolve($response.data)
            }).catch(exception=>{
                console.log(exception)
                return Promise.reject(null)
            })
        case 'DELETE':
            return axios.delete(getBaseUrl($options),$headers)
            .then($response =>{
                return Promise.resolve($response.data)
            }).catch(exception=>{
                console.log(exception);
                return Promise.reject(null)
            })
        case 'PUT':
            return axios.put(getBaseUrl($options),$options.data,$headers)
            .then($response =>{
                return Promise.resolve($response.data)
            }).catch(exception=>{
                console.log(exception)
                return Promise.reject(null)
            })
    }
}
export default {sendRequest}
