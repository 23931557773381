import Enum  from '../enum/index.vue'
import Bank  from '../bank/index.vue'

export default [
    {
        path: 'misc',
        children: [
            {
                path: "enum",
                component: Enum,
            },
            {
                path: "bank",
                component: Bank,
            }
        ]
    }
]