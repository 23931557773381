<template>
    <div :elevation="0" class="tabContainer " 
    :style="!adminClicked ? 'border-top: 1px solid #f6f6f6;' : 'border-top:none'">
        <div class="tabBar" v-for="menu in allMenus" :key="menu">{{ module_name }}
            <button :id="menu.module_code" :class="activeTab === menu.module_name ? 'active':activeTab === menu.module_code ? 'active':'' " class="mr-7" @click="changeTab(menu )">
                    <v-icon size="14" :class="menu.icon" 
                     :style="{ fontSize: menu.icon === 'tibura-packaging' ? '20px' : 'inherit', marginBottom: menu.icon === 'tibura-packaging' ? '-10px' : 'inherit' }"/>
                    <span class="pl-1">{{ menu.module_name }}</span>
            </button>
        </div>
    </div>
    <template  v-for="menu in allMenus" :key="menu">
        <TabMenus :options="menu.menu" :activator="`#${menu.module_code}`" @optionselected="openNav" />
    </template>
    
</template>
  
<script>
import TabMenus from '@/components/TabMenus.vue';
// import MenuGroupService from '@/api/service/dms/MenuGroupService';
import AppService from '@/api/service/AppService';

export default {
    components: { TabMenus },
    data() {
        return {
            allMenus: null,
            adminClicked: false,
            activeTab: null,
            activeMenuArray: [],
        };
    },
    created() {
        setTimeout(() => {
            const routePath = this.$route.path.split('/')
            // this.$store.commit("setMainMenu", routePath[1]);
            this.$store.commit('setSideMenuGroup', {"activity_group_code": routePath[2]});
        }, 100); 
    },
    computed:{
        signIn() {
            return this.$store.getters.getMainMenu;
        }
    },
    watch:{
        signIn(newValue){
            this.activeTab=newValue.replace(/#/g, '');
        },
        allMenus(){
            const routePath = this.$route.path.split('/')
            const filteredMenu = this.allMenus && this.allMenus.length > 0 ? this.allMenus.find(obj=>obj.menu.find(item=>item.activity_group_code == routePath[2])) : null
            this.activeTab= filteredMenu ? filteredMenu.module_name : null
        }
    },
    methods: {
        async getMenus() {
            let $res = await AppService.getMenulist({"roleId":101});
            $res = $res.message;
            this.allMenus = $res 
        },
        changeTab(menu) {
            this.activeTab=menu.module_name
            // this.$store.commit('setSideMenuGroup',menu)

              /*  if (this.activeTab === tab) {
                    this.$emit('closeNav', false);
                    return;
                }
                this.activeTab = tab;
                if (tab !== 'Home') {
                    let menuData = this.allMenus.find((e) => e.title === tab);
                    this.activeMenuArray = menuData ? menuData.subMenuItems : [];
                }
                this.adminClicked = false;
                this.$emit('closeNav', false);*/
        },
        openNav(item) {
            this.$store.commit('setSideMenuGroup',item)
        },
    },
    mounted() {
        this.getMenus();
    },
};
</script>
  
<style scoped>
.tabContainer {
    z-index: 1004;
    position: fixed;
    left: 0;
    align-items: center;
    display: flex;
    width: 100vw;
    background: #FFF;
    height: fit-content !important;
    padding-top: 15px;
    padding-bottom: 13px;
    padding-left: 32px;
}

.tabBar {
    /* padding: 10px 0px; */
    display: flex;
    gap: 2.5vw;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

button {
    cursor: pointer;
    text-decoration: none;
    color: #121619;
    font-size: 14px;
    font-weight: 400;
}

.textPadding {
    padding-bottom: 3px;
}

button.active {
    color: #0F62FE;
    border-bottom: 2px solid #0F62FE;
}

button:hover {
    color: #0F62FE;
    border-bottom: 2px solid #0F62FE;
}

button:hover .icon {
    font-weight: 800;
    color: #0F62FE;
    border-bottom: 2px solid #0F62FE;
}

button:active {
    font-weight: 800;
    color: #0F62FE;
    border-bottom: 2px solid #0F62FE;
}

button.active .icon {
    color: white;
}
</style>