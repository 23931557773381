<template>
  <div class="ma-2"> 
      <v-card flat >
        <v-card-item>
          <v-card-title>
            Security
          </v-card-title>
        </v-card-item>
        <v-card-text>
          <v-row dense>
            <v-col cols="3">
              <v-label style="line-height:16px;">Module</v-label>
              <TBAutoComplete 
              @focus="resetShowButtons('module')" 
              :items="modules" v-model="formData.module_id" placeholder="Select Modules" density="compact"
                variant="outlined" name="name" value="id" required=true @update:modelValue="moduleChanged" />
            </v-col>
            <v-col cols="1" class="mt-7">
              <v-btn 
              @mouseenter="addButtonOptions['module']=true"
              @mouseleave="addButtonOptions['module']=true"
               v-if="addButtonOptions['module']" class="ml-2 add-button" width="22px" height="22px"  
              density="compact" icon="mdi-plus" @click="showDialog('module')"></v-btn>
            </v-col>
            <v-col cols="3" v-if="selectedModule.id">
              <v-label style="line-height:16px;">Activity Group</v-label>
              <TBAutoComplete 
              @focus="resetShowButtons('activityGroup')" 
              :disbled="!formData.module_id"
              :items="activityGroups" v-model="formData.activity_group_id" placeholder="Select Activity group"
                density="compact" variant="outlined" name="name" value="id" required=true
                @update:modelValue="activityGroupChanged" />
            </v-col>
             <v-col cols="1" class="mt-7">
              <v-btn 
              @mouseenter="addButtonOptions['module']=true"
              @mouseleave="addButtonOptions['module']=true"
               v-if="addButtonOptions['activityGroup'] "  
              class="ml-2 add-button" width="22px" height="22px"  
              density="compact" icon="mdi-plus" @click="showDialog('activityGroup')"></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row dense>
        &nbsp;
      </v-row>
  
      <v-row dense>
        <v-col cols="3">
          <v-card flat height="60vh" 
          @mouseover="resetShowButtons('activity')" 
          @mouseleave="addButtonOptions['activity']=false"
          >
            <v-card-title>
              Activities
              <v-btn v-if="addButtonOptions['activity']"   class="ml-2 add-button" width="22px" height="22px"  
              density="compact" icon="mdi-plus" @click="showDialog('activity')"></v-btn>
            </v-card-title>
            <v-card-text>
  
              <TBListView
           
               :listItems="activities" value="id" text="name" return-object=true add-on-click=false
                @item-active="activityChanged" @item-selected="activityChanged" />
  
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card flat height="60vh"
          @mouseover="resetShowButtons('actions')"
          @mouseleave="addButtonOptions['actions']=false"
          >
            <v-card-title>
              <span>Actions</span>
              <span>
                <v-btn v-if="addButtonOptions['actions']" 
                class="ml-2 add-button" width="22px" height="22px"  density="compact" icon="mdi-plus" 
                @click="showDialog('actions')"></v-btn>
              </span>
            </v-card-title>
            <v-card-text style="overflow: auto;height: 85%;">
              <v-row dense class="rowheader pa-2">
                <v-col cols="3" class="text-left">
                  Name
                </v-col>
                <v-col cols="2" class="text-left">
                  Code
                </v-col>
                <v-col cols="4" class="text-left">
                  Event
                </v-col>
                <v-col cols="2" class="text-left">
                  Active
                </v-col>
              </v-row>
              <v-row dense v-for="action of selectedActivity.actions" v-bind:key="action" class="pa-1">
                <v-col cols="3" class="text-left">
                  {{ action.name }}
                </v-col>
                <v-col cols="2" class="text-left">
                  <v-chip>
                    {{ action.code }}
                  </v-chip>
                </v-col>
                <v-col cols="4" class="text-left">
                  {{ action.event }}
                </v-col>
                <v-col cols="2" class="text-left">
                  <span class="helper-legend"
                    :style="action.active == 1 ? { 'background-color': 'rgb(0, 178, 61)', 'border-radius': '2px' } : { 'background-color': 'red', 'border-radius': '2px' }">
                  </span>
                  {{ action['active'] == 1 ? 'Active' : 'InActive' }}
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-action v-if=false style="position: absolute;right: 10px;bottom: 10px;">
              <v-spacer></v-spacer>
              <v-btn class="mr-2 cancel">Cancel</v-btn>
              <v-btn @click="save" class="save" color="primary" type="submit">Save</v-btn>
            </v-card-action>  
          </v-card>
        </v-col>
      </v-row>
    <v-dialog persistent v-model="dialog" width="700px">
      <AddAction @closeDialog="close()" 
      v-if="dialogOptions['actions']"
      @apply-changes = "applyChanges"
      :viewType="viewType" />
      <AddModule @closeDialog="close()" 
      v-if="dialogOptions['module']"
      @reload = "moduleAdded"
      :viewType="viewType" />
      <AddActivityGroup @closeDialog="close()" 
      :moduleId = "selectedModule.id"
      v-if="dialogOptions['activityGroup']"
      @reload = "activityGroupAdded"
      :viewType="viewType" />
      <AddActivity @closeDialog="close()" 
      :activityGroupId = "selectedActivityGroup.id"
  
      v-if="dialogOptions['activity']"
      @reload = "activityAdded"
      :viewType="viewType" />
    </v-dialog>
    </div>
  </template>
  
  <script>
  import AddModule from './AddModule.vue'
  import AddActivity from './AddActivity.vue'
  import AddActivityGroup from './AddActivityGroup.vue'
  import ModuleService from '@/api/service/admin/ModuleService';
  import ActivityGroupService from '@/api/service/admin/ActivityGroupService';
  import ActivityService from '@/api/service/admin/ActivityService';
  import { TBAutoComplete } from '@tiburadev/vuecomponents';
  import { TBListView } from '@tiburadev/vuecomponents';
  import AddAction from './AddAction.vue'
  export default {
    components: {
      TBAutoComplete,
      TBListView,
      AddAction,
      AddModule,
      AddActivityGroup,
      AddActivity
    },
    data() {
      return {
        addButtonOptions:{
          "module":false,
          "activityGroup":false,
          "activity":false
        },
        dialogOptions:{
          "module":false,
          "activityGroup":false,
          "activity":false,
          "action":false
        },
        modules: [],
        activityGroups: [],
        activities: [],
        selectedModule: {},
        selectedActivityGroup: {},
        selectedActivity: {},
        dialog: false,
        defaultActions: [
          { "code": "list", "name": "List Page", "event": "", "active": true },
          { "code": "create", "name": "Create", "event": "", "active": true },
          { "code": "view", "name": "View", "event": "", "active": true },
          { "code": "update", "name": "Update", "event": "", "active": true },
          { "code": "delete", "name": "Delete", "event": "", "active": true },
          { "code": "file-upload", "name": "File Upload", "event": "", "active": true },
          { "code": "bulk-update", "name": "Bulk Update", "event": "", "active": true },
          { "code": "download", "name": "Download", "event": "", "active": true }
  
  
        ],
        formData: {
          module_id: null,
          activity_group_id: null,
          activity_id: null,
          actions: [
  
          ]
        }
      }
    },
    created() {
      this.getModule();
    },
    methods: {
      async getModule() {
        this.modules = await ModuleService.findAll({ active: 1 });
      },
      async getActivityGroups() {
        this.activityGroups = await ActivityGroupService.findAll({ active: 1, module_id: this.selectedModule.id });
        this.activities=[]
      },
      async getActivities() {
        this.activities = await ActivityService.findAll({ "activity_group_id": this.selectedActivityGroup.id })
      },
      moduleAdded($item) {
        this.selectedModule = $item
        this.modules.push($item)
        this.formData.module_id = $item.id
        this.formData.activity_group_id=null
        this.formData.activity_id=null
        
      },
      activityGroupAdded($item) {
        this.selectedActivityGroup = $item
        this.activityGroups.push($item)
        this.formData.activity_group_id = $item.id
        this.formData.activity_group_id=null
        this.formData.activity_id=null
      
  
      },
      activityAdded($item) {
        this.selectedActivity = $item
        this.activities.push($item)
        this.formData.activity_id = $item.id
      },
      moduleChanged($item) {
        this.selectedModule = this.modules.filter((obj) => { return obj.id == $item })
        this.selectedModule = this.selectedModule[0]
        this.formData.activity_group_id=null
        this.formData.activity_id=null
        this.actions=[]
        this.selectedActivity.actions=[]
        this.getActivityGroups()
      },
      showDialog($action) {
        this.dialog=true
       this.resetDialog()
        this.dialogOptions[$action]=true
      },
      resetShowButtons($action){
        this.addButtonOptions={
          "module":false,
          "activityGroup":false,
          "activity":false,
          "action":false
        }
        this.addButtonOptions[$action]=true
      },
      resetDialog() {
        this.dialogOptions={
          "module":false,
          "activityGroup":false,
          "activity":false,
          "action":false
        }
      },
      activityGroupChanged($item) {
        this.selectedActivityGroup = this.activityGroups.filter((obj) => { return obj.id == $item })
        this.selectedActivityGroup = this.selectedActivityGroup[0]
        this.getActivities()
        this.selectedActivity.actions=[]
      },
      close() {
        this.dialog = false
        this.resetDialog()
      },
      async save() {
        try {
          let $saveData = {"id":this.selectedActivity.id,"actions":JSON.stringify(this.selectedActivity.actions)}
          await ActivityService.update($saveData)
        } catch (e) {
          console.log(e)
            this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Something Went Wrong",
            });
       }
      },
      async applyChanges($item) {
        this.dialog = false
        let $index =  this.selectedActivity.actions.findIndex((obj) => { return $item.code == obj.code })
        if($index<0) {
          this.selectedActivity.actions.push($item)
        } else {
          this.selectedActivity.actions[$index]=Object.assign({},$item)
        }
        this.save()
      },
      async getActions($id) {
        let $activity = await ActivityService.findOne({"id":$id})
        $activity = $activity[0]
        let $actions = $activity.actions ? JSON.parse($activity.actions) : []
        return $actions
      },
     async activityChanged($item) {
        // alert(JSON.stringify($item))
       
        this.selectedActivity = $item
        this.selectedActivity.actions = await this.getActions($item.id)
        let $save=false
        let $actions =this.selectedActivity.actions
  
        for (let $action of this.defaultActions) {
          let $index = $actions.findIndex((obj) => { return $action.code == obj.code })
          console.log($index)
          if ($index < 0) {
            $save =true
            let $act = Object.assign({}, $action)
            
            $act.event = this.selectedActivity.code + '-' + this.convert($action.code)
            $actions.push($act)
          }
        }
        this.selectedActivity.actions = $actions
        if($save) { this.save() } 
      },
    convert($code) {
      if($code=='view') {
        return 'viewed'
      }else if($code=='file-upload') {
        return 'file-uploaded'
      }else if($code=='bulk-update') {
        return 'bulk-updated'
      } else {
        return $code+'d'
      }
    }
  }
    
  }
  </script>
  
  <style></style>
  <style scoped>
  .rowheader {
    position: sticky;
      top: 0;
      z-index: 1;
    background-color: #D7EAF4;
    font-weight: 600;
  }
  
  .link {
    color: #000;
    text-decoration: none;
  }
  
  .link:hover {
    color: #000;
    text-decoration: underline;
  }
  .add-button {
    background-color:#1258AB;
    color:#fff;
    font-size:12px;
  }
  .open-issues {
    background-color: #E2D6F8;
    color: #272727;
    font-weight: 10px;
    font-weight: 400;
  }
  
  .date {
    color: orange;
  }
  </style>