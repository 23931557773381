<template>
  <div>
    <v-navigation-drawer v-if="menuItems && menuItems.length > 0" fixed location="left" :key="navDrawerPinned"
      :rail="rail" mobile-breakpoint="0" style="height:100vh; z-index: 0; transform: translateX(0%); border-radius: 2px;"
      class="sidemenu">

      <v-list densit="compact">
        <!-- <v-list-title class="main-list-Title pl-8 py-8">{{ listTitle }}</v-list-title> -->
        <v-list-subheader>
          <span class="main-list-Title pl-5 pb-5 font-weight-bold">
            <v-icon size="x-small font-weight-bold" :class="activityGroup && activityGroup.icon" />
            {{ activityGroup && activityGroup.activity_group_name }}
          </span>
        </v-list-subheader>
        <v-divider class="pa-0 mb-1 dividerr" :color="''"></v-divider>
        <div v-if="menuItems && menuItems.length > 0">
          <template v-for="menu in menuItems" :key="menu.title">
           
            <v-list-item :class="selected === menu.activity_code ? 'active' : ''" class="subTitjjle ml-10"
              @click="menuClick(menu)">
              <v-icon size="14" style="margin-right: 8px;" :class="menu.icon" 
                     :style="{ fontSize: menu.icon === 'tibura-packaging' ? '20px' : 'inherit', marginBottom: menu.icon === 'tibura-packaging' ? '-10px' : 'inherit' }"/>
                                 {{ menu.activity_name ? menu.activity_name : null }}
            </v-list-item>
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import AppService from '@/api/service/AppService';

export default {
  props: ['query'],
  components: {
  },
  data() {
    return {
      dialog: false,
      selected: null,
      groupId: null,
      listTitle: null,
      showMenu: false,
      show: false,
      userName: localStorage.getItem("_CUSER_"),
      menuItems: [],
    };
  },
  created() {
    setTimeout(() => {
      const routePath = this.$route.path.split('/')
      this.selected = routePath[3]
    }, 500)
  },
  computed: {
    activityGroup() {
      return this.$store.getters.getSideMenuGroup
    }
  },
  watch: {
    activityGroup: {
      deep: true,
      handler(oldValue, newValue) {
        // this.getSideMenu()
        console.log('old', oldValue.activity_group_id)
        console.log('new', newValue.activity_group_id)

        if (oldValue.activity_group_id != newValue.activity_group_id || oldValue.activity_group_id == null)
          this.getSideMenu()
      }

    },
    menuItems() {
    }
  },

  methods: {
    async getSideMenu() {
      try {
        if (this.activityGroup && this.activityGroup.activity_group_code) {
          let $res = await AppService.getMenulist({
            "roleId": 101,
            "menuType": "activity",
            "groupId": this.activityGroup.activity_group_code
          });

          $res = $res && $res.message ? $res.message : [];
          this.$store.commit('setSideMenuGroup', {
            "activity_group_code": $res.activity_group_code,
            "activity_group_name": $res.activity_group_name,
            "activity_group_id": $res.activity_group_id,
            "icon": $res.icon
          });

          this.menuItems = $res && $res.menu && $res.menu.length > 0 ? $res.menu : [];

          let $default = this.menuItems&& this.menuItems.length > 0 ? this.menuItems.slice(0, 1) : null;
          $default = $default[0]
          // this.menuClick($default) 
          console.log($default, '');

        }
      } catch (error) {
        console.error('Error in getSideMenu:', error);
      }
    },

    menuClick($item) {
      this.selected = $item.activity_code
      this.$router.push($item.route);
    },
    close() {
      this.dialog = false;
    },
    activemenu(item) {
      this.rail = false;
      if (item.name == "Logout") {
        this.dialog = true;
      }
      if (item.name == "Settings") {
        this.showMenu = !this.showMenu;
      }
    },
  },
};
</script>
  
<style scoped>

>>>.v-navigation-drawer{
    margin-top: 59px !important;
    border: none;
    border-radius: 2px;
  }
>>>.v-list-group__header__append-icon {
  display: none !important;
}

>>>.v-list-item--density-default.v-list-item--one-line {
  min-height: 37px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.main-list-Title {
  color: #697077;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.subTitjjle {
  color: #121619;
  font-size: 13px;
  /* padding: 10px 20px; */
  font-weight: 400;
  /* border-radius: 4px; */
  /* margin-bottom: 4px; */
}

/* .subTitjjle:hover .dividerr{
    color: #edf5ff;
    caret-color: #edf5ff;
    background-color: #edf5ff;
  } */

.active {
  font-size: 14px;
  font-weight: 500;
  color: #0F62FE;
  background-color: #edf5ff;

  /* .dividerr{
    color: #edf5ff;
    caret-color: #edf5ff;
    background-color: #edf5ff;
  } */

}

</style>
  