<template>
    <v-navigation-drawer v-model="Fdrawer" location="right" temporary :width="500">
        <div flat style="display: grid;grid-auto-flow: column;width: 100%;gap: 20px;">

            <div style="float: left;width:240px;padding-left: 10px;">
                <v-form @submit.prevent="saveFilter" ref="form">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <span class="filterData">Save Filter</span>
                        <span class="my-2" >
                            <v-btn  :disabled="showReadOnly" :loading="loading"  type="submit" class="save-filter" variant="outlined"><v-icon 
                           start icon="mdi-bookmark" ></v-icon>Save
                           </v-btn>
                           
                        </span>
                    </div>

                    <v-divider class="border-opacity-50"/>

                    <div class="mb-3">
                        <div>
                            <v-label class="pb-1 label-text">Filter Name
                                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
                            </v-label>
                            <TBTextField :rules="filterRule" placeholder="Name" v-model="filterName"
                                :disabled="showReadOnly" />
                        </div>
                        </div>
                        <div>

                           <div>
                            <v-list density="compact">
                                <v-list-item class="my-1" @click="selectFilter(item)" v-for="(item, i) in existFilters"
                                    :key="i" :value="item" color="primary">
                                    <v-list-item-title class="d-flex justify-space-between">
                                        <span class="listItems">{{ item.name }}</span>
                                        <span class="inner listItems wrapper" @click.stop="deleteFilter(item)">
                                            <i class=" tibura-trash bounce_button"></i>
                                        </span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </div>
                    </div>
                </v-form>

            </div>

            <div style="width:240px;padding-right:10px;">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <span class="filterData">Filter</span>
                    <span class="my-2">
                        <v-btn @click="applyFilter()" class="apply-filter" variant="outlined">Apply</v-btn>
                    </span>
                </div>


                <v-divider class="border-opacity-50" />
                <div class="mb-3">
                    <div>
                        <div v-for="item in CustomFilterData" :key="item">

                            <v-label class="pb-1 label-text" :for="item.label">{{ item.label }}</v-label>
                            <div class="mb-1" v-if="item.field_type == 'textfield'">
                                <TBTextField v-model="item.defaults" :placeholder="item.label">
                                </TBTextField>
                            </div>

                            <div class="mb-1" v-if="item.field_type == 'autocomplete'">
                                <TBAutoComplete v-if="item.source_type" v-model="item.defaults" density="compact"
                                    variant="outlined" :items="item.source_type" :item-title="item.text"
                                    :item-value="item.value" :placeholder="item.label"></TBAutoComplete>
                            </div>
                            <div class="mb-1" v-if="item.field_type == 'enum'">
                                <TBAutoComplete v-if="item.source_type" v-model="item.defaults" density="compact"
                                    variant="outlined" :items="item.source_type" :item-title="item.text"
                                    :item-value="item.value" :placeholder="item.label"></TBAutoComplete>
                            </div>
                            <div class="mb-1" v-if="item.field_type == 'status'">
                                <TBAutoComplete v-model="item.defaults" density="compact" label="Status" variant="outlined"
                                    :items="statusData" item-title="name" item-value="id" placeholder="Status">
                                </TBAutoComplete>
                            </div>
                            <div class="mb-1" v-if="item.field_type == 'textArea'">
                                <v-textarea v-model="item.defaults" density="compact" class="elevation-0"
                                    variant="solo"></v-textarea>
                            </div>
                            <div class="mb-1" v-if="item.data_type == 'date' || item.data_type == 'datetime'">
                                <span class="p-float-label">
                                    <TBTextField style="width: 100%;" :inputId="item.label" selectionMode="range"
                                        type="date" :manualInput="false"
                                        :panelStyle="{ width: '400px', zIndex: '9999999999', transformOrigin: 'center bottom', top: '170px', borderRadius: '10px' }"
                                        panelClass="calendar-container" v-model="item.defaults">
                                    </TBTextField>

                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { TBTextField, TBAutoComplete } from '@tiburadev/vuecomponents';
import ListModelDetailService from '@/api/service/technical/ListModelDetailService';
import EnumService from '@/api/service/miscellaneous/EnumService';
import ListModelFilterService from '@/api/service/ListModelFilter/ListModelFilterService'
import CommonService from '@/api/service/common/CommonService';
import ListModelFilterDetailsService from '@/api/service/ListModelFilter/ListModelFilterDetailsService'
import lodash from 'lodash'
export default {

    props: [
        "model"
    ],
    components: {
        TBTextField,
        TBAutoComplete
    },

    data() {
        return {
            showReadOnly:false,
            filterName: null,
            Fdrawer: false,
            CustomFilterData: [],
            existFilters: [],
            filterRule: [(value) => !!value || "Filter Name Is Required"],
            loading: false,
        }
    },

    created() {
        // if(this.Fdrawer){
        //     alert('ji')
        //     this.getExistFilters()
        //     this.getListModelDetails()
        // }     
    },
        methods: {
        openFilter() {
            this.Fdrawer = true
            this.getExistFilters()
            this.getListModelDetails()
        },


        async getListModelDetails() {
            if (this.model) {
                let $modelDetails = await ListModelDetailService.getFilterColumn({ tableName: this.model });
                $modelDetails = lodash.orderBy($modelDetails.data, ['sequence'], ['asc'])

                console.log($modelDetails , 'lplplplp')
                this.getDropdownData($modelDetails)
            }
        },
        async getExistFilters() {
            try {
                let $exist = await ListModelFilterService.getFilters({ modelName: this.model });
                if ($exist && $exist.length > 0 && $exist[0].list_model_filter_data) {
                    let rawFilter = $exist[0].list_model_filter_data;
                    this.existFilters = JSON.parse(rawFilter);
                } else {
                    console.error('Unexpected response format.');
                }
            } catch (error) {
                console.error('An error occurred while fetching filters:', error.message);
            }
        },

        async deleteFilter(item) {
            if (item && item.id) {
                let obj = {};
                obj.id = item.id;
                obj.deleted = 1;
                let output = await ListModelFilterService.update(obj)
                console.log(output, 'kokokoko');
                this.getExistFilters()                 
            }


        },

        async getDropdownData($data) {
            let filterData = []
            for (let i = 0; i < $data.length; i++) {
                if ($data[i].filter_required == 1) {
                    let str = $data[i].label
                    $data[i].label = str.charAt(0).toUpperCase() + str.slice(1);
                    if ($data[i].field_type == "autocomplete") {
                        $data[i].source_type = await this.getValues($data[i].source, $data[i].column_name);
                        if ($data[i].source_type.length == 1) {
                            $data[i].defaults = $data[i].source_type[0][$data[i].value];
                        }
                    } else if ($data[i].field_type == "enum") {
                        $data[i].source_type = await this.getEnumValues($data[i].source);
                    }
                    filterData.push($data[i])
                }
            }
            this.CustomFilterData = JSON.parse(JSON.stringify(filterData));
        },

        async getValues($source) {
            let res = await CommonService.findAllOptions({ tableName: $source })
            return res ? res : [];
        },

        async getEnumValues(source) {
            let res = await EnumService.findAll({ domain: source })
            return res ? res : [];
        },


        async saveFilter() {
            this.showReadOnly =true
            let validation = await this.$refs.form.validate()
            if (!validation.valid) {
                console.log("Not valid")
            }
            else {
                this.loading =true
                this.showReadOnly =true;
                let $data = {};
                $data.name = this.filterName;
                $data.modelName = this.model;
                let $filter = await ListModelFilterService.create($data);
                this.CustomFilterData.forEach(async element => {
                    let $obj = {};
                    $obj.name = element.lable;
                    $obj.column_name = element.column_name;
                    $obj.defaults = element.defaults;
                    $obj.model_filter_id = $filter.id;
                    await ListModelFilterDetailsService.create($obj);
                    
                })
                this.showReadOnly =false
                this.loading =false
                this.filterName = null
                this.applyFilter()
                this.getExistFilters()
            }
        },

        async selectFilter(data) {
            const res = await ListModelFilterDetailsService.selectedFilter({ id: data.id });
            const array1 = res;
            this.CustomFilterData = this.CustomFilterData.map(obj2 => {
                const matchingObj1 = array1.find(obj1 => obj1.column_name === obj2.column_name);
                obj2.defaults = matchingObj1 ? matchingObj1.defaults : obj2.defaults;
                return obj2;
            });
        },

        applyFilter() {
            const filterObject = {};
            this.CustomFilterData.forEach(obj => {
                if (obj.defaults !== null && obj.defaults !== '') {
                    filterObject[obj.column_name] = obj.defaults;
                }
            });
            this.$emit('filterData', filterObject)
            this.Fdrawer=false
        }
    }
}
</script>


<style scoped>
/* .inner{font-size: 20px;} */
.apply-filter {

    background: #0F62FE;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    /* text-align: center; */
    padding: 5px 15px;
}

.save-filter {
     background: #0F62FE;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    /* text-align: center; */
    padding: 5px 15px;
}

.v-input--horizontal {
    grid-template-areas:
        "prepend control append"
        "a messages b";
    grid-template-columns: max-content minmax(0, 1fr) max-content;
    grid-template-rows: auto auto;
    display: contents !important;
}


.listItems {
    color: #121619;
    font-size: 13px;
    font-weight: 400;
}
.mdi:before{
    margin-left: 9px;
}

@keyframes bounce {

    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-310px)
    }

    60% {
        -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}

.wrapper {
    position: relative;
    right: 100%;
    width: fit-content;
    display: flex;
    align-content: center;
    justify-content: center;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count:1;
    -webkit-animation-iteration-count: 1;
    background-color: transparent;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.wrapper:hover{
    background-color: #3C5AAA;
}

span:hover {
    cursor: pointer;
    color: #fff;
    animation-name: bounce;
    padding-top: 3px;
    -moz-animation-name: bounce;
    
}
</style>

