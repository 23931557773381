import ListProduct from "../listProduct/index.vue"
import ActiveProducts from "../activeProduct/index.vue"
import InactiveProduct from "../inactiveProduct/index.vue"
export default [
    {
        path: 'manage-products',
        children: [
            {
                path: "list-products",
                component: ListProduct,
            },
            {
                path: "active-products",
                component: ActiveProducts,
            },
            {
                path: "inactive-products",
                component: InactiveProduct,
            }

        ]
    }
]