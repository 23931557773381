import Components from '../../Tax/pricingComponent/index.vue'
import PricingModels from '../models/index.vue'
import HsnGuide from '../hsnguide/index.vue'

export default [
    {
        path: 'product-costing',
        children: [
            {
                path: "components",
                component: Components,
            },
            {
                path: "hsn-guide",
                component: HsnGuide,
            },
            {
                path: "models",
                component: PricingModels,
            }
        ]
    }
]